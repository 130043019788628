import classNames from 'classnames'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import Input from '../../../components/Forms/Input/Input'
import { InputRequisitesSearch } from '../../../components/input-requisites-search/input-requisites-search'
import { Select } from '../../../components/select/select'
import { managersStore } from '../../../mobx/managers'
import { ArvalFields, ArvalFieldsAuction, ArvalFullFieldsItem, ArvalKeys } from '../Type'
import styles from "./GeneralInformation.module.scss"

interface GeneralInforamationProps {
	className?: string
	fields: ArvalFields
	type: ArvalKeys
	fillFields: ArvalFullFieldsItem
	disabledFields: {
		expires_at: boolean
		sellerable: boolean
	}
	onSaveAuction(form: any): void
}

const NAMES: any = {
	sequential_increase: 'На повышение',
	sequential_decrease: 'На понижение',
	parallel_increase: 'Парралельный',
	buy_now: 'Buy Now'
}

const DEFAULTS_FORM = {
	auction_start_time_shift: 0,
	responsible_id: 0,
	interval: 0,
	is_seller_hidden: false,
	sellerable_type: '',
	sellerable_id: 0,
	sellerable_title: ''
}

const DEFAULT_INTERVAL = 60
const INTERVALS = [
	{ value: 1, label: "1 мин" },
	{ value: 2, label: "2 мин" },
	{ value: 5, label: "5 мин" },
	{ value: 15, label: "15 мин" },
	{ value: "custom", label: "Другой" },
]

const GeneralInforamation = observer((props: GeneralInforamationProps) => {
	const { className, fields, disabledFields, type, fillFields, onSaveAuction } = props

	const [defaultTab, setDefaultTab] = useState(type)
	const [auction, setAuction] = useState<ArvalFieldsAuction>()
	const managers = managersStore.managers
	const [form, setForm] = useState(DEFAULTS_FORM)
	const [intervalValue, setIntervalValue] = useState<any>(DEFAULT_INTERVAL)
	const [timeUnit, setTimeUnit] = useState(60)
	const [customInterval, setCustomInterval] = useState<any>(0)

	useEffect(() => {
		setAuction(fields[type].values.auction)
		setForm(fillFields ? fillFields.options.auction : DEFAULTS_FORM)
		const currentInterval = INTERVALS.find(x => x.value === fillFields?.options?.auction?.interval)
		if (currentInterval?.value === 1 ||
			currentInterval?.value === 2 ||
			currentInterval?.value === 5 ||
			currentInterval?.value === 15) {
			setIntervalValue(currentInterval.value)
		} else {
			setIntervalValue('custom')
			setCustomInterval(fillFields?.options?.auction?.interval / 60)
		}
	}, [])

	useEffect(() => {
		if (!managersStore.managers.length) {
			managersStore.fetch()
		}
	}, [])

	const onClickSetTimeUnit = (time: number) => {
		setTimeUnit(time)
	}

	useEffect(() => {
		onSaveAuction(form)
	}, [JSON.stringify(form)])

	return (
		<div className={classNames(styles.GeneralInforamation, className)}>
			<div className="info-wrapper">
				<div className="d-flex align-items-center justify-content-between mt-2">
					<div className="name w-30">Тип аукциона</div>
					<div className="d-flex align-items-center position-relative w-70">
						<div className="btn-wrapper">
							{Object.entries(fields).map(([key, value]) =>
								<span
									key={value.key}
									className={clsx(
										"btn-wrapper__type cursor-default",
										{ 'btn-wrapper__type-active': value.key === defaultTab }
									)}
								>
									{NAMES[value.key]}
								</span>
							)}
						</div>
					</div>
				</div>
				{auction?.auction_start_time_shift &&
					<div className="d-flex align-items-center justify-content-between mt-2">
						<div className="name w-30">{auction.auction_start_time_shift.name}</div>
						<div className="d-flex align-items-center w-70">
							<Input
								name={'auction_start_time_shift'}
								type={'number'}
								className="input-date"
								onInput={(e: any) => {
									setForm({
										...form,
										['auction_start_time_shift']: e.target.value
									})
								}}
								value={form.auction_start_time_shift}
								required={true}
							/>
						</div>
					</div>
				}
				{auction?.interval && (
					<div className="d-flex align-items-center justify-content-between mt-2">
						<div className="name w-30">
							Интервал продления/понижения
						</div>
						<div className="d-grid gap-5px w-70 justify-content-start">
							<div className="btn-wrapper">
								{INTERVALS.map((item: any, index) => {
									return (
										<span
											onClick={() => {
												setIntervalValue(item.value)
												setForm({
													...form,
													['interval']: item.value
												})
											}}
											key={`interval-${index}`}
											className={clsx(
												"btn-wrapper__type cursor-pointer",
												{
													"btn-wrapper__type-active":
														intervalValue ===
														item.value,
												}
											)}
										>
											{item.label}
										</span>
									)
								})}
							</div>
						</div>
					</div>
				)}
				{intervalValue === "custom" && (
					<div className="d-flex align-items-center justify-content-end">
						<div className="d-flex w-70 gap-10px align-items-center mt-2">
							<Input
								className="input-time"
								type="number"
								onInput={(e: any) => {
									setCustomInterval(e.target.value)
									setForm({
										...form,
										['interval']: parseInt(e.target.value) * timeUnit
									})
								}}
								placeholder="0"
								value={customInterval}
							/>
							<div className="btn-wrapper__time">
								<span className="btn-wrapper__time-type">
									мин
								</span>
							</div>
						</div>
					</div>
				)}
				{auction?.sellerable &&
					<div className="d-flex align-items-center justify-content-between mt-2">
						<div className="name w-30">Продавец</div>
						<div className="d-flex align-items-center position-relative w-70">
							<InputRequisitesSearch
								name="sellerable_id"
								defaultValue={form.sellerable_title}
								placeholder="Наименование продавца"
								disabled={disabledFields.sellerable}
								typeId={0}
								onChange={(sellerable_id: any, sellerable_type: any) => {
									setForm({
										...form,
										['sellerable_id']: sellerable_id,
										['sellerable_type']: sellerable_type
									})
								}}
							/>
						</div>
					</div>
				}
				{auction?.is_seller_hidden &&
					<div className="d-flex align-items-center justify-content-end">
						<label className="d-flex align-items-center w-70 mt-2 gap-5px cursor-pointer">
							<input
								type="checkbox"
								name="is_seller_hidden"
								onChange={(e) => {
									setForm({
										...form,
										[e.target.name]: e.target.checked,
									})
								}}
								checked={form.is_seller_hidden}
							/>
							<span className="text-seller">Скрыть продавца</span>
						</label>
					</div>
				}
				{auction?.responsible &&
					<div className="d-flex align-items-center justify-content-between mt-3">
						<div className="name w-30">Ответственный менеджер</div>
						<div className="d-flex align-items-center position-relative w-70">
							<Select
								options={managers?.map((user) => ({
									value: user.id,
									label: user.full_name,
								}))}
								valueId={form.responsible_id}
								size="m"
								placeholder="Не выбран"
								onChange={({ value }: { value: number }) => {
									console.log(value)
									setForm({
										...form,
										['responsible_id']: value,
									})
								}}
							/>
						</div>
					</div>
				}
			</div>
		</div>
	)
})

export default GeneralInforamation