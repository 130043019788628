import { getImportOptionsFillableFields, getImportOptionsManager, putImportOptionsManager } from '@api/import'
import { NO_PERMISSION_URL } from '@utils/common'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { meStore } from '../../../mobx/me'
import EditAuctionLots from '../EditAuctionLots/EditAuctionLots'
import GeneralInforamation from '../GeneralInformation/GeneralInformation'
import { ArvalKeys } from '../Type'
import styles from "./ArvalAuctionForm.module.scss"

interface ArvalAuctionFormProps {
	className?: string
	disabledFields: {
		expires_at: boolean
		sellerable: boolean
	},
	typeManager: 'arval_xml'
	auctionType: ArvalKeys
}

const steps: any = [
	{ description: "Общая информация" },
	{ description: "Лоты" }
]

const components = [GeneralInforamation, EditAuctionLots]

const ArvalAuctionForm = (props: ArvalAuctionFormProps) => {
	const { className, disabledFields, typeManager, auctionType } = props

	const [fields, setFields] = useState<any>()
	const [fillFields, setFillFields] = useState<any>()
	const [activeStep, setActiveStep] = useState(0)
	const [type, setType] = useState<ArvalKeys>(auctionType)
	const [formGeneral, setFormGeneral] = useState<any>()
	const [success, setSuccess] = useState<-1 | 0 | 1>(0)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<any>()

	const fetchInit = async () => {
		await getImportOptionsFillableFields().then(setFields)
		await getImportOptionsManager(typeManager, auctionType).then(setFillFields)
		setLoading(true)
	}

	useEffect(() => {
		fetchInit()
	}, [])

	if (meStore.noPermission("admin.auctions.edit")) return <Navigate to={NO_PERMISSION_URL} />

	const Component = components[activeStep]

	const onSaveAuction = (formAuction: any) => {
		setFormGeneral(formAuction)
	}

	const onSaveLots = async (formLots: any) => {
		const data = await putImportOptionsManager(typeManager, {
			auction_type: type,
			options: {
				auction: formGeneral,
				lots: formLots
			}
		})
		if (data.status === 422) {
			setSuccess(-1)
			setError(data.errors)
		} else {
			setSuccess(1)
		}
		setTimeout(() => {
			setSuccess(0)
			setError(null)
		}, 5000)
	}

	return (
		<div className={clsx('add-auction-wrapper', styles.ArvalAuctionForm)}>
			<h2 className="head-text mb-4">Настройки API Арвал</h2>
			{loading &&
				<>
					<GeneralInforamation
						className={'mb-2'}
						fields={fields}
						fillFields={fillFields}
						type={type}
						onSaveAuction={onSaveAuction}
						disabledFields={disabledFields}
					/>
					<EditAuctionLots
						fields={fields}
						fillFields={fillFields}
						type={type}
						success={success}
						onSaveAuction={onSaveLots}
						disabledFields={disabledFields}
					/>
					{Boolean(error) && (
						<div className="mt-2 mb-2 alert alert-danger">
							{JSON.stringify(error, null, " ")}
						</div>
					)}
				</>
			}
		</div>
	)
}

export default ArvalAuctionForm