import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as Auction } from '@assets/side-bar-icons/auction-icon.svg'
import { ReactComponent as Buy } from '@assets/side-bar-icons/buy.svg'
import { ReactComponent as Calendar } from '@assets/side-bar-icons/calendar.svg'
import { ReactComponent as Cars } from '@assets/side-bar-icons/cars.svg'
import { ReactComponent as Company } from '@assets/side-bar-icons/company.svg'
// import { ReactComponent as Documents } from '@assets/side-bar-icons/documents.svg';
import { ReactComponent as Exchange } from '@assets/side-bar-icons/exchange.svg'
import { ReactComponent as Favorite } from '@assets/side-bar-icons/favorite.svg'
// import { ReactComponent as Finance } from '@assets/side-bar-icons/finance.svg';
import { COUNTRY } from '@api/config'
import { ReactComponent as Profile } from '@assets/side-bar-icons/profile.svg'
import { ReactComponent as Reporting } from '@assets/side-bar-icons/reporting.svg'
import { ReactComponent as SelectedUsersIcon } from '@assets/side-bar-icons/selected-users-icon.svg'
import { ReactComponent as Sell } from '@assets/side-bar-icons/sell.svg'
import { ReactComponent as Torgs } from '@assets/side-bar-icons/torgs.svg'
import { ReactComponent as Users } from '@assets/side-bar-icons/users.svg'
import { layoutStore } from '@mobx/layout'
import { meStore } from '@mobx/me'
import { getLogoSrc, getMiniLogoSrc, ROOT_URL } from '@utils/common'

import './sidebar.scss'

export const Sidebar = observer(({ open, isMobile }) => {
	const [items, setItems] = useState({ auctions: [], account: [], admin: [] })

	useEffect(() => {
		const newItems = {
			auctions: [],
			account: [
				{ id: 7, name: 'Профиль', icon: <Profile />, path: '/profile' },
			],
			admin: [],
		}

		if (meStore.data?.impersonate_as?.entity?.roles?.length) {
			const canViewAuctions = meStore.hasPermission('auctions.view') || meStore.hasPermission('admin.auctions.view')

			if (canViewAuctions) {
				newItems.auctions.push({ id: 1, name: 'Текущие торги', icon: <Torgs />, path: '/current-trades' })
			}

			if (meStore.hasPermission('exchange-offers.view')) {
				newItems.auctions.push({ id: 2, name: 'Электронная биржа', icon: <Exchange />, path: '/exchange' })
			}

			if (canViewAuctions) {
				newItems.auctions.push({ id: 3, name: 'Избранное', icon: <Favorite />, path: '/favourite-lots' })
				newItems.auctions.push({
					id: 4,
					name: 'Календарь торгов ',
					icon: <Calendar />,
					path: '/auction-calendar',
				})
			}

			if (
				(meStore.hasPermission('auctions.view') || meStore.hasPermission('admin.auctions.view'))
				&& meStore.hasPermission('auctions.bets.add') && meStore.hasPermission('sales.view')) { // признак покупателя
				newItems.account.unshift({ id: 6, name: 'Покупаю', icon: <Buy />, path: '/buy' })
			}

			if (meStore.hasPermission('auctions.view.seller')) {
				newItems.account.unshift({ id: 5, name: 'Продаю', icon: <Sell />, path: '/sell' })
			}

			if (!isMobile && meStore.hasPermission('admin.auctions.view')) {
				newItems.admin.push({ id: 11, name: 'Аукционы', icon: <Auction />, path: '/auctions' })
			}

			if (!isMobile && meStore.hasPermission('admin.users.view')) {
				newItems.admin.push({
					id: 12,
					name: 'Пользователи',
					icon: <Users />,
					selected_icon: <SelectedUsersIcon />,
					path: '/users',
				})
			}

			if (!isMobile && meStore.hasPermission('admin.companies.view')) {
				newItems.admin.push({ id: 8, name: 'Компании', icon: <Company />, path: '/companies' })
			}

			if (meStore.hasPermission('admin.sales.view') || meStore.hasPermission('sales.edit') || meStore.hasPermission('admin.sales.edit')) {
				newItems.admin.push({ id: 13, name: 'Автомобили', icon: <Cars />, path: '/cars' })
			}

			if (!isMobile && meStore.hasPermission('reports.use')) {
				newItems.admin.push({ id: 14, name: 'Отчетность', icon: <Reporting />, path: '/reporting' })
			}

			if (!isMobile && meStore.hasPermission('admin.users.edit')) {
				newItems.admin.push({ id: 14, name: 'API Арвал', icon: <Auction />, path: '/arval/add-auction' })
			}
		}

		setItems(newItems)
	}, [meStore.data])

	const { pathname } = useLocation()

	const renderLink = (item) => {
		return (
			<Link
				className="d-flex align-items-center"
				to={item.path}
				onClick={() => {
					if (isMobile) {
						layoutStore.toggleSidebar()
					}
				}}
			>
				<div className="svg-wrapper">
					{item.name === 'Пользователи' && item.path === pathname
						? item.selected_icon
						: item.icon}
				</div>
				<div className="item-name">{item.name}</div>
			</Link>
		)
	}

	return (
		<div className={clsx('side-bar-wrapper', `side-bar-wrapper_${COUNTRY}`, { 'closed-side-bar': !open })}>
			{open && (
				<Link to={ROOT_URL}>
					<img src={getLogoSrc()} className="logo" alt="logo" />
				</Link>
			)}
			<OutsideClickHandler
				disabled={!isMobile || (isMobile && !open)}
				onOutsideClick={(e) => {
					if (e.target.id !== 'burger-img') {
						layoutStore.toggleSidebar()
					}
				}}
			>
				<div className="items">
					{!open && (
						<Link to={ROOT_URL}>
							<img src={getMiniLogoSrc()} className="mini-logo" alt="mini-logo" />
						</Link>
					)}
					{items.auctions.length > 0 && (
						<div className="nav-category">
							<p className="title">Аукционы</p>
							{items.auctions.map((item) => (
								<div
									key={item.id}
									className={
										item.path === pathname
											? 'selected d-flex align-items-center  item-wrapper position-relative'
											: 'd-flex align-items-center some-example item-wrapper position-relative'
									}
								>
									{renderLink(item)}
								</div>
							))}
							<hr />
						</div>
					)}
					{items.account.length > 0 && (
						<div className="nav-category">
							<p className="title">Личный кабинет</p>
							{items.account.map((item) => (
								<div
									key={item.id}
									className={
										item.path === pathname
											? 'selected d-flex align-items-center item-wrapper position-relative'
											: 'd-flex align-items-center item-wrapper position-relative'
									}
								>
									{renderLink(item)}
								</div>
							))}
							<hr />
						</div>
					)}
					{items.admin.length > 0 && (
						<div className="nav-category">
							<p className="title">Администрирование</p>
							{items.admin.map((item) => (
								<div
									key={item.id}
									className={
										item.path === pathname
											? 'selected selected-users d-flex align-items-center item-wrapper position-relative'
											: 'd-flex align-items-center item-wrapper position-relative'
									}
								>
									{renderLink(item)}
								</div>
							))}
						</div>
					)}
				</div>
			</OutsideClickHandler>
		</div>
	)
})
