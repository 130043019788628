import { getCurrencySymbol, isKZ } from '@utils/common'
import classNames from 'classnames'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import Input from '../../../components/Forms/Input/Input'
import { ArvalFields, ArvalFieldsLots, ArvalFullFieldsItem, ArvalKeys } from '../Type'
import styles from "./EditAuctionLots.module.scss"

interface EditAuctionLotsProps {
	className?: string
	fields: ArvalFields
	fillFields: ArvalFullFieldsItem
	type: ArvalKeys
	success: -1 | 0 | 1
	disabledFields: {
		expires_at: boolean
		sellerable: boolean
	}
	onSaveAuction(form: any): void
	onStepChange?(): void
}

const DEFAULTS_FORM = {
	starting_price: 0,
	minimal_step: 0,
	expires_at: '',
	is_vat_included: false,
	commission: 0,
	minimal_commission: 0,
	surcharge: 0
}

const EditAuctionLots = (props: EditAuctionLotsProps) => {
	const { className, success, disabledFields, fields, fillFields, type, onStepChange, onSaveAuction } = props
	const [fieldLots, setFieldLots] = useState<ArvalFieldsLots>()
	const [form, setForm] = useState(DEFAULTS_FORM)
	const [formattedDate, setFormattedDate] = useState<any>('')

	useEffect(() => {
		fillFields && setForm(fillFields.options.lots)
		setFieldLots(fields[type].values.lots)
		const expiresAt = fillFields?.options?.lots?.expires_at
		if (expiresAt)
			setFormattedDate(moment(expiresAt).format("YYYY-MM-DDTHH:mm"))
	}, [])

	const onSaveData = () => {
		onSaveAuction(form)
	}

	return (
		<div className={classNames(styles.EditAuctionLots, 'auction-lots')}>
			<div className="info-wrapper">
				{fieldLots?.starting_price &&
					<div className="d-flex align-items-center justify-content-between mt-2">
						<div className="name w-30">{fieldLots.starting_price.name}</div>
						<div className="d-flex align-items-center position-relative w-70">
							<Input
								type={'number'}
								className="input-date"
								placeholder={isKZ ? "В тенге" : "В рублях"}
								onInput={(e: any) => {
									setForm({
										...form,
										['starting_price']: e.target.value
									})
								}}
								value={form.starting_price}
								required={true}
							/>
						</div>
					</div>
				}
				{fieldLots?.minimal_step &&
					<div className="d-flex align-items-center justify-content-between mt-2">
						<div className="name w-30">{fieldLots.minimal_step.name}</div>
						<div className="d-flex align-items-center position-relative w-70">
							<Input
								type={'number'}
								className="input-date"
								placeholder={isKZ ? "В тенге" : "В рублях"}
								onInput={(e: any) => {
									setForm({
										...form,
										['minimal_step']: e.target.value
									})
								}}
								required={true}
								value={form.minimal_step}
							/>
						</div>
					</div>
				}
				{fieldLots?.expires_at &&
					<div className="d-flex align-items-center justify-content-between mt-2">
						<div className="name w-30">{fieldLots.expires_at.name}</div>
						<div className="d-flex align-items-center position-relative w-70">
							<Input
								type={'datetime-local'}
								className="input-date"
								onInput={(e: any) => {
									setForm({
										...form,
										['expires_at']: e.target.value
									})
									if (e.target.value) {
										const data = moment(e.target.value).format("YYYY-MM-DDTHH:mm")
										setFormattedDate(data)
									}
								}}
								value={formattedDate}
								disabled={disabledFields.expires_at}
							/>
						</div>
					</div>
				}
				{fieldLots?.is_vat_included &&
					<div className="d-flex align-items-center justify-content-between mt-3 mb-3">
						<div className="name w-30">{fieldLots.is_vat_included.name}</div>
						<label className="d-flex align-items-center position-relative w-70">
							<div className="checkbox-wrapper">
								<input
									type="checkbox"
									name="is_vat_included"
									onChange={(e) => {
										setForm({
											...form,
											[e.target.name]: e.target.checked,
										})
									}}
									checked={form.is_vat_included}
								/>
								{form.is_vat_included ? "Да" : "Нет"}
							</div>
						</label>
					</div>
				}
				<div className="d-flex align-items-center justify-content-between mt-2">
					<div className="name w-30">Комиссия</div>
					<div className="d-flex align-items-center position-relative w-70 gap-15px">
						<div className="position-relative">
							<Input
								name="commission"
								onInput={(e: any) => {
									setForm({
										...form,
										[e.target.name]: e.target.value,
									})
								}}
								value={form.commission}
								className="auction-lots__input-commission"
								placeholder="0"
							/>
							<span className="auction-lots__placeholder-symbol">
								%
							</span>
						</div>
						<div>
							<div className="input-name">
								Минимум
							</div>
							<div className="position-relative">
								<Input
									name="minimal_commission"
									onInput={(e: any) => {
										setForm({
											...form,
											[e.target.name]: e.target.value,
										})
									}}
									value={form.minimal_commission}
									className="auction-lots__input-minimal-commission"
									placeholder="0"
								/>
								<span className="auction-lots__placeholder-symbol">
									{getCurrencySymbol()}
								</span>
							</div>
						</div>
					</div>
				</div>
				{fieldLots?.surcharge &&
					<div className="d-flex align-items-center justify-content-between mt-2">
						<div className="name ц-30">{fieldLots.surcharge.name}, {getCurrencySymbol()}</div>
						<div className="d-flex align-items-center position-relative w-70">
							<Input
								type={'number'}
								className="input-date"
								placeholder={isKZ ? "" : "30 000"}
								onInput={(e: any) => {
									setForm({
										...form,
										['surcharge']: e.target.value
									})
								}}
								required={true}
								value={form.surcharge}
							/>
						</div>
					</div>
				}
			</div>
			<div className="auction-buttons-footer">
				{success > 0 &&
					<div className={styles.success}>Настройки сохранены</div>
				}
				<button
					onClick={onSaveData}
					className="btn btn-primary mt-2 d-flex align-items-center gap-10px"
					type="button"
				>
					Сохранить
				</button>
			</div>
		</div>
	)
}

export default EditAuctionLots