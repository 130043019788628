import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"

import { addAllUsersToGroup, addUsersToGroupRequest, deleteUsersFromGroupRequest, getAllUsersFromGroup } from "@api/user-groups"
import { filteredUsers, getUsersList } from "@api/users"
import { Button } from "@components/button/button"
import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search'
import { Input } from "@components/input/input"
import { Pagination } from "@components/pagination/pagination"
import { Table } from "@components/table/table"
import { COLUMNS } from "./utils"

import "./users-group-modal.scss"

const PER_PAGE = [25, 50, 100, 250, 500]

export const UsersGroupModal = ({ groupId, groupUsers, show, requestUsers, filteredRole, onHide }) => {
	const [selectedRows, setSelectedRows] = useState([])
	const [users, setUsers] = useState()
	const [form, setForm] = useState({
		"filters[middle_name]": "",
		"filters[first_name]": "",
		"filters[last_name]": "",
		"filters[companies][]": ""
	})

	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
		classes: "selection-row",
		selected: selectedRows,
		onSelectAll: (isSelect, rows) => {
			if (isSelect) {
				setSelectedRows(rows.map((item) => item.id))
			} else {
				setSelectedRows([])
			}
		},
		onSelect: (row, isSelect, rowIndex) => {
			if (isSelect) {
				setSelectedRows((prev) => [...prev, row.id])
			} else {
				setSelectedRows((prev) => prev.filter((item) => item !== row.id))
			}
		}
	}

	const requestAllUsers = (params) => {

		const defaultParams = {
			'filters[all_roles][]': filteredRole,
			'filters[status_codes][]': 'user_active',
			...params
		}

		getUsersList(defaultParams).then((resp) => {
			if (!resp.message) {
				setUsers(resp)
			}
		})
	}

	useEffect(() => {
		requestAllUsers({ page: 1, per_page: PER_PAGE[1] })
		getAllUsersFromGroup(groupId).then(setSelectedRows)
	}, [])

	const addUsers = async () => {
		const usersToRemove = []

		groupUsers.data.forEach((item) => {
			if (!selectedRows.includes(item.id)) {
				usersToRemove.push(item.id)
			}
		})

		if (usersToRemove.length) {
			await deleteUsersFromGroupRequest(groupId, { user_ids: usersToRemove })
		}

		await addUsersToGroupRequest(groupId, { user_ids: selectedRows })
		requestUsers()
		onHide()
	}

	const addAllUsers = async () => {
		await addAllUsersToGroup(groupId)
		requestUsers()
		onHide()
	}

	const getFilteredUsersList = () => {
		let data = {
			'filters[all_roles][]': filteredRole
		}
		Object.entries(form).forEach(([key, value]) => {
			if (value) data[key] = value
		})
		filteredUsers(data).then((resp) => {
			if (!resp.message) {
				setUsers(resp)
			}
		})
	}

	const clearAllFilters = () => {
		setForm({
			"filters[middle_name]": "",
			"filters[first_name]": "",
			"filters[last_name]": "",
			"filters[companies][]": ""
		})
		requestAllUsers({ page: 1, per_page: PER_PAGE[1] })
	}

	const handleInputChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		})
	}

	const handleInputSearchChange = (id) => {
		setForm({
			...form,
			"filters[companies][]": id
		})
	}

	return (
		<div className="access-users-modal-wrapper">
			<Modal show={show} onHide={onHide} className="users-group-modal-wrapper">
				<div className="filters-wrapper mx-0">
					<div>
						<div className="d-flex align-items-center flex-wrap gap-5px">
							<Input
								placeholder="Фамилия"
								name="filters[last_name]"
								value={form["filters[last_name]"]}
								onChange={handleInputChange}
								className="input_small"
								size="xs"
							/>
							<Input
								placeholder="Имя"
								name="filters[first_name]"
								value={form["filters[first_name]"]}
								onChange={handleInputChange}
								className="input_small"
								size="xs"
							/>
							<Input
								placeholder="Отчество"
								name="filters[middle_name]"
								value={form["filters[middle_name]"]}
								onChange={handleInputChange}
								className="input_small"
								size="xs"
							/>
							<InputRequisitesSearch
								name="filters[companies]"
								placeholder="Компания"
								onChange={handleInputSearchChange}
								size="xs"
							/>
						</div>
						<div className="mt-20px d-flex gap-10px">
							<Button onClick={getFilteredUsersList} preset="primary" size="s">
								Применить фильтры
							</Button>
							<Button onClick={clearAllFilters} className="d-flex gap-5px align-items-center"
								preset="ghost" size="s">
								<img src="/img/icons/filters-off-icon.svg" />
								Очистить
							</Button>
						</div>
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-end gap-2 header mb-3 buttons">
					<button onClick={addAllUsers} className="btn btn-secondary btn_s" type="button">
						Добавить всех пользователей
					</button>
					<button onClick={addUsers} className="btn btn-primary btn_s" type="button">
						Сохранить
					</button>
				</div>

				{users?.data ? (
					<Table
						data={users.data}
						columns={COLUMNS}
						selectRow={selectRow}
					/>
				) : (
					<p>No data</p>
				)}
				<Pagination
					data={users}
					defaultPerPage={50}
					onChange={requestAllUsers}
					perPageList={PER_PAGE}
				/>
			</Modal>
		</div>
	)
}
