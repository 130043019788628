import { GET, PUT } from "./request"

export const getImportOptionsFillableFields = () => {
	return GET('import/options/fillable-fields')
}

export const getImportOptionsManager = (importManager: string, auction_type: string) => {
	return GET(`import/options/${importManager}?auction_type=${auction_type}`)
}

export const putImportOptionsManager = (importManager: string, params: any) => {
	return PUT(`import/options/${importManager}`, params)
}